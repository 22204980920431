import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'select2';
import { FancyMsg } from './FancyMsg';
import { Loader } from './Loader';
$(() => {
    window.fancyMsg = new FancyMsg('');
    window.loader = new Loader();
    const select2Class = '.apply-select2';
    const singleSelect = select2Class + ':not([multiple])';
    $(singleSelect).select2();
    const multipleSelect = select2Class + '[multiple]';
    $(multipleSelect).select2({
        placeholder: 'Select some options'
    });
    $(multipleSelect).on('select2:unselect', function (unselectEvent) {
        if (!unselectEvent.originalEvent) {
            return;
        }
        unselectEvent.originalEvent.stopPropagation();
    });
});
